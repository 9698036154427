/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import { useRouter } from 'next/router';
import { makeStyles } from '@material-ui/core/styles';
import loginGqlService from '@modules/login/services/graphql';
import { removeIsLoginFlagging } from '@helper_auth';
import { miniDrawerWidthSeller, drawerWidthSeller } from '@modules/theme/layout/helpers';
import Link from 'next/link';
import { getLocalStorage } from '@helper_localstorage';
import { storeConfigVar } from '@core/services/graphql/cache';
import { useTranslation } from '@i18n';
import LanguageSelect from '@modules/theme/layout/components/languageSelect';
import {
    PRIMARY_DARK,
    BLACK,
    TABLE_GRAY,
    PRIMARY,
    BORDER_COLOR,
} from '@theme_color';
import { custDataNameCookie } from '@config';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidthSeller,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidthSeller,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        border: '0',
        boxShadow: '2px 0px 20px #4D2F821A',
        justifyContent: 'space-between',
        '&.drawer-mobile': {
            width: '100%',
        },
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: miniDrawerWidthSeller,
        },
        border: '0',
        boxShadow: '2px 0px 20px #4D2F821A',
    },
    togleMenuButton: {
        width: 24,
        height: 24,
        position: 'absolute',
        top: '16px',
        right: '16px',
    },
    togleMenuIcon: {
        fontSize: 30,
        color: BLACK,
        borderRadius: '3px',
        background: '#fff',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    swiftOmsLogo: {
        cursor: 'pointer',
        padding: 12,
        '&.open': { justifyContent: 'flex-start' },
        '&.close': { justifyContent: 'center' },
        '& img': { height: 45 },
    },
    divMenu: {
        color: BLACK,
        '& .itemText span': {
            fontSize: 14,
            textTransform: 'capitalize',
        },
        justifyContent: 'center',
        '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: 12,
        },
        '&.open': {
            '& .MuiListItemText-root': {
                flex: 'none',
            },
        },
        // '&.close': {
        //     padding: '0 12px',
        // },
    },
    menuList: {
        padding: '0 12px',
        flex: '1 1 auto',
        '&.open': {
            padding: '0 12px',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20,
        },
    },
    menuItem: {
        marginTop: 8,
        padding: '14px 12px',
        borderRadius: 12,
        justifyContent: 'space-between',
        '&:hover': {
            background: TABLE_GRAY,
            color: PRIMARY,
            '& .itemIcon img': {
                filter: 'invert(24%) sepia(94%) saturate(2110%) hue-rotate(295deg) brightness(81%) contrast(101%)',
            },
        },
        '&.open .itemText': {
            flex: '1 1 auto',
        },
        '&.active': {
            color: PRIMARY,
            '& .itemIcon img': {
                filter: 'invert(24%) sepia(94%) saturate(2110%) hue-rotate(295deg) brightness(81%) contrast(101%)',
            },
            '& .itemText span': {
                fontWeight: 'bold',
            },
            '& .MuiSvgIcon-root': {
                rotate: '-180deg',
            },
        },
        '& .MuiSvgIcon-root': {
            transition: '0.3s',
        },
        '& .itemIcon': {
            minWidth: 'auto',
            '& img': {
                maxWidth: 30,
            },
        },
        '& .itemIcon img': {
            width: 24,
            height: 24,
        },
    },
    menuListProfile: {
        margin: '12px 12px 4px',
        borderRadius: 4,
        flex: '1 1 auto',
        border: '1px solid #E5E7EB',
        '& .menuItemProfileText .MuiTypography-root': {
            fontWeight: 600,
        },
    },
    menuItemProfile: {
        padding: 12,
        gap: 12,
        '&:hover': {
            background: TABLE_GRAY,
            color: PRIMARY,
        },
        '&.active': {
            '& .MuiSvgIcon-root': {
                rotate: '-180deg',
            },
        },
        '& .MuiSvgIcon-root': {
            transition: '0.3s',
        },
    },
    menuItemProfileIcon: {
        minWidth: 'unset',
    },
    menuChildItem: {
        paddingLeft: 70,
        '&.active span': {
            color: '#BE1F93',
            fontWeight: 'bold',
        },
        '&:hover span': {
            color: '#BE1F93',
            fontWeight: 'bold',
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    menuGrandChildItem: {
        paddingLeft: 90,
        '&.active span': {
            color: '#BE1F93',
            fontWeight: 'bold',
        },
        '&:hover span': {
            color: '#BE1F93',
            fontWeight: 'bold',
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    togleMobile: {
        padding: '0 12px',
        '& button': {
            padding: '0px 8px 4px 8px',
        },
        '& h4': {
            display: 'inline-block',
        },
    },
    languageMobile: {
        display: 'inline-block',
        float: 'right',
        marginTop: 20,
        '& div > button': {
            fontSize: 13,
            padding: '4px 5px',
        },
    },
    hr: {
        border: 0,
        height: 1,
        background: BORDER_COLOR,
        marginBottom: 20,
    },
    mobileUser: {
        padding: '0 12px',
        '& .user-list': {
            display: 'flex',
            borderRadius: 4,
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 15px',
            marginTop: 15,
        },
        '& .user-icon': {
            marginRight: 20,
            display: 'inline-flex',
        },
        '& .item-icon': {
            width: 20,
            height: 20,
        },
        '& .user-text': {
            flex: '1 1 auto',
            '&._red': {
                color: '#F43030',
            },
        },
    },
    logoBottom: {
        paddingTop: 20,
        '& img': {
            width: 110,
        },
    },
    nested: {
        '&:hover': {
            background: TABLE_GRAY,
            color: PRIMARY,
        },
        '& .MuiTypography-root ': {
            fontSize: 14,
        },
    },
}));

const Sidebar = ({
    activeParentMenu,
    setActiveParentMenu,
    activeChildMenu,
    setActiveChildMenu,
    open,
    setOpen,
    menuList,
    storeLogo,
}) => {
    const router = useRouter();
    const classes = useStyles();
    const { t } = useTranslation('common');
    const [removeCustomerToken] = loginGqlService.removeToken();
    const [openLogo, setOpenLogo] = React.useState(false);
    const [activeParentMenuProfile, setActiveParentMenuProfile] = React.useState();

    const handleClickParent = (menu) => {
        if (menu.key === (activeParentMenu && activeParentMenu.key)) {
            setActiveParentMenu(null);
        } else {
            setActiveParentMenu(menu);
            if (menu.url) router.push(menu.url);
        }
        setOpen(true);
    };
    const handleClickChild = (menu) => {
        if (menu.key === (activeChildMenu && activeChildMenu.key)) {
            setActiveChildMenu(null);
        } else {
            setActiveChildMenu(menu);
            if (menu.url) router.push(menu.url);
        }
        setOpen(true);
    };

    const handleClick = (menu) => {
        if (menu === (activeParentMenuProfile)) {
            setActiveParentMenuProfile(null);
        } else {
            setActiveParentMenuProfile(menu);
        }
        setOpenLogo(!openLogo);
    };

    const handleLogout = () => {
        removeCustomerToken()
            .then(() => {
                removeIsLoginFlagging();
                Cookies.remove(custDataNameCookie);
                router.push('/login');
            })
            .catch(() => { });
    };

    const aclDetail = getLocalStorage('acl') ? JSON.parse(getLocalStorage('acl')) : {};
    // const config_acl = getLocalStorage('config_acl') ? JSON.parse(getLocalStorage('config_acl')) : {};

    const storeConfig = storeConfigVar();
    const isExternal = storeConfig?.swiftoms_channel_enable_external_provider === '1';

    const SidebarContent = () => (
        <>
            <Hidden mdUp>
                <div className={classes.togleMobile}>
                    <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={() => setOpen(false)}>
                        <CloseIcon className={classes.togleMenuIcon} />
                    </IconButton>
                    <h4>{t('common:menu_utama')}</h4>
                    <div className={classes.languageMobile}>
                        <LanguageSelect color={PRIMARY_DARK} />
                    </div>
                </div>
            </Hidden>
            <div>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={clsx(classes.menuListProfile, open ? 'open' : 'close')}
                >
                    <ListItem
                        button
                        onClick={() => handleClick('menuprofile')}
                        className={
                            clsx(classes.menuItemProfile,
                            open ? 'open' : 'close',
                            (activeParentMenuProfile) === 'menuprofile' && 'active')
                        }
                    >
                        <ListItemIcon className={clsx(classes.menuItemProfileIcon)}>
                            <img alt="" src="/assets/img/layout/profile_logo.svg" style={{ width: 32 }} />
                        </ListItemIcon>
                        <ListItemText className="menuItemProfileText" primary="ENOMISE" />
                        <KeyboardArrowDownIcon style={{ color: '#4B5563', width: 20 }} />
                    </ListItem>
                    <Collapse in={openLogo} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Item" />
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </div>
            <List className={clsx(classes.menuList, open ? 'open' : 'close')}>
                {menuList
                    && menuList.map((menu) => (
                        <div key={menu.key}>
                            {menu.key === 'dashboard' && (
                                <div className={classes.divMenu} key={menu.key}>
                                    <Link href={`${menu.url}`} key={menu.key}>
                                        <a href={`${menu.url}`}>
                                            <ListItem
                                                button
                                                className={clsx(
                                                    classes.menuItem,
                                                    open ? 'open' : 'close',
                                                    menu.key === (activeParentMenu && activeParentMenu.key) && 'active',
                                                )}
                                                onClick={() => handleClickParent(menu)}
                                            >
                                                <ListItemIcon className="itemIcon">
                                                    <img alt="" src={`/assets/img/layout/${menu.key}.svg`} />
                                                </ListItemIcon>
                                                <ListItemText className="itemText" primary={menu.label} />
                                            </ListItem>
                                        </a>
                                    </Link>
                                </div>
                            )}
                        </div>
                ))}
                {menuList
                    && menuList.map((menu) => {
                        if ((isExternal && menu.isExternal === false) || (!isExternal && menu.isExternal === true)) {
                            return null;
                        }
                        return (
                            <div key={menu.key}>
                                {aclDetail && (aclDetail?.acl_code?.includes(menu.aclCode) || menu.notInAcl) && !menu.hide && (
                                    <div
                                        // eslint-disable-next-line no-nested-ternary
                                        id={menu.key === 'catalog' ? 'guide-catalog' : menu.key === 'channelseller' ? 'guide-sales' : null}
                                        className={clsx(classes.divMenu, open ? 'open' : 'close')}
                                        key={menu.key}
                                    >
                                        <Link href={menu.url || '#'} key={menu.key}>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
                                            <a onClick={(e) => e.preventDefault()}>
                                                <ListItem
                                                    button
                                                    className={clsx(
                                                        classes.menuItem,
                                                        open ? 'open' : 'close',
                                                        menu.key === (activeParentMenu && activeParentMenu.key) && 'active',
                                                    )}
                                                    onClick={() => handleClickParent(menu)}
                                                >
                                                    <ListItemIcon className="itemIcon">
                                                        <img alt="" src={`/assets/img/layout/${menu.key}.svg`} />
                                                    </ListItemIcon>
                                                    <ListItemText className="itemText" primary={menu.label} />
                                                    {menu?.children?.length > 0 && <KeyboardArrowDownIcon style={{ olor: '#4B5563', width: 20 }} />}
                                                </ListItem>
                                            </a>
                                        </Link>
                                        {menu && menu.children && menu.children.length && (
                                            <Collapse in={activeParentMenu && activeParentMenu.key === menu.key} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {menu.children.map((menuChild) => (
                                                        <div key={menuChild.key}>
                                                            {((aclDetail && aclDetail?.acl_code?.includes(menuChild.aclCode)) || menuChild.notInAcl)
                                                                && !menuChild.hide && (
                                                                    <Link href={menuChild.url} key={menuChild.key}>
                                                                        <a>
                                                                            <ListItem
                                                                                button
                                                                                key={menuChild.key}
                                                                                className={clsx(
                                                                                    classes.menuChildItem,
                                                                                    menuChild.key === (activeChildMenu && activeChildMenu.key) && 'active',
                                                                                )}
                                                                                onClick={() => handleClickChild(menuChild)}
                                                                            >
                                                                                <ListItemText className="itemText" primary={menuChild.label} />
                                                                            </ListItem>
                                                                        </a>
                                                                    </Link>
                                                                )}
                                                        </div>
                                                    ))}
                                                </List>
                                            </Collapse>
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })}
            </List>

            <Hidden smUp>
                <div className={classes.mobileUser}>
                    <hr className={classes.hr} />
                    <div className="user-list">
                        <div className="user-icon">
                            <img className="item-icon" alt="" src="/assets/img/logout.svg" />
                        </div>
                        <div className="user-text _red">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className="linkOut" href="#" onClick={handleLogout}>
                                {t('common:Sign_Out')}
                            </a>
                        </div>
                    </div>
                </div>
            </Hidden>
            <div style={{ padding: '20px 30px', textAlign: 'center' }}>
                <div className={classes.logoBottom}>
                    <img alt="logo oms" src={storeLogo?.logo || '/assets/img/logo-seller.svg'} />
                </div>
            </div>
        </>
    );

    const SidebarMobile = () => (
        <Drawer
            variant="temporary"
            open={open}
            onClose={() => setOpen(false)}
            className={clsx(classes.drawer, 'drawer-mobile', open ? classes.drawerOpen : classes.drawerClose)}
            classes={{
                paper: clsx('drawer-mobile', open ? classes.drawerOpen : classes.drawerClose),
            }}
            ModalProps={{ keepMounted: true }}
            style={{ justifyContent: 'none' }}
        >
            {SidebarContent()}
        </Drawer>
    );

    const SidebarDesktop = () => (
        <Drawer
            variant="permanent"
            open={open}
            className={clsx(classes.drawer, classes.drawerOpen)}
            classes={{
                paper: classes.drawerOpen,
            }}
        >
            {SidebarContent()}
        </Drawer>
    );

    return (
        <div id="sidebar">
            <Hidden mdUp>{SidebarMobile()}</Hidden>
            <Hidden smDown>{SidebarDesktop()}</Hidden>
        </div>
    );
};

export default Sidebar;
