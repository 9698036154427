/* eslint-disable import/prefer-default-export */
const getConfig = require('next/config');
const { docsGuide } = require('@root/swift.config');
const { getAppEnv } = require('@helpers/env');

const getHost = () => {
    const { publicRuntimeConfig } = getConfig.default();
    const globalHost = publicRuntimeConfig.BASE_URL;
    return globalHost;
};

const getStoreHost = () => {
    const { publicRuntimeConfig } = getConfig.default();
    let storeHost = publicRuntimeConfig.GRAPHQL_URL;
    storeHost = storeHost.replace('graphql', '');
    return storeHost;
};

const getDocsGuide = () => {
    const appEnv = getAppEnv();
    const urlDocs = docsGuide[appEnv] || docsGuide.prod;
    return urlDocs;
};

module.exports = { getHost, getStoreHost, getDocsGuide };
