/* eslint-disable import/prefer-default-export */
import { optionsDashboard } from '@modules/theme/helpers/menuDashboard';
import { optionsLoyalty } from '@modules/theme/helpers/menuLoyalty';
import { optionsAdsCampaign } from '@modules/theme/helpers/menuAdsCampaign';
import { optionsAnalytic } from '@modules/theme/helpers/menuAnalytic';

export const helpersMenuList = (t) => [
    ...optionsDashboard(t),
    ...optionsAdsCampaign(t),
    ...optionsLoyalty(t),
    ...optionsAnalytic(t),
];
