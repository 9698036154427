/* eslint-disable import/prefer-default-export */
export const optionsSeller = (t) => [
    {
        aclCode: 'seller_order',
        key: 'order',
        label: t('menu:Order'),
        children: [
            {
                aclCode: 'seller_order',
                key: 'order_list',
                label: t('menu:Order_List'),
                url: '/seller/order/orderlist',
            },
            {
                aclCode: 'seller_order',
                key: 'order_failed',
                label: t('menu:Failed_Order_List'),
                url: '/seller/order/failedorder',
            },
        ],
    },
    {
        aclCode: 'seller_catalog',
        key: 'catalog',
        label: t('menu:Catalog'),
        url: '/seller/catalog',
        isMultichannel: false,
    },
    {
        aclCode: 'seller_catalog',
        key: 'catalogproduct',
        label: t('menu:Catalog'),
        isMultichannel: true,
        children: [
            {
                aclCode: 'seller_catalog_master_product',
                key: 'masterproduct',
                label: t('menu:Master_Product'),
                url: '/seller/catalogproduct/masterproduct',
            },
            {
                aclCode: 'seller_catalog_channel_product',
                key: 'channelproduct',
                label: t('menu:Channel_Product'),
                children: [
                ],
            },
            {
                aclCode: 'seller_catalog_channel_product_edit',
                key: 'masschange',
                label: t('menu:Mass_Change'),
                url: '/seller/catalogproduct/masschange',
            },
        ],
    },
    {
        aclCode: 'seller_stock',
        key: 'stock',
        label: t('menu:Stock'),
        url: '/seller/stock',
        isMultichannel: false,
    },
    {
        aclCode: 'seller_stock',
        key: 'stock',
        label: t('menu:Stock'),
        url: '/seller/stockchannel',
        isMultichannel: true,
    },
    // {
    //     key: 'discussion',
    //     label: t('menu:Discussion'),
    //     url: '/seller/discussion',
    // },
    // {
    //     key: 'review',
    //     label: t('menu:Review'),
    //     url: '/seller/review',
    // },
    {
        aclCode: 'seller_return',
        key: 'returns',
        label: t('menu:Return'),
        url: '/seller/return',
    },
    {
        aclCode: 'seller_store',
        key: 'storesetting',
        label: t('menu:Store_Setting'),
        url: '/seller/storesetting',
    },
    {
        aclCode: 'seller_income',
        key: 'income',
        label: t('menu:Income'),
        url: '/seller/income/balance',
    },
    {
        aclCode: 'seller_promotion',
        key: 'promotion',
        label: t('menu:Promotion'),
        url: '/seller/promotion',
    },
    {
        aclCode: 'seller_sales_channel',
        key: 'saleschannel',
        label: t('menu:Store_Channel'),
        url: '/seller/saleschannel',
        isMultichannel: true,
    },
    {
        aclCode: 'seller_apps',
        key: 'apps',
        label: t('menu:Apps'),
        url: '/seller/apps',
        isMultichannel: true,
    },
    {
        aclCode: 'seller_user',
        key: 'manageuser',
        label: t('menu:Manage_User'),
        url: '/seller/manageuser',
    },
    {
        aclCode: 'seller_report',
        key: 'report',
        label: t('menu:Report'),
        children: [
            {
                aclCode: 'seller_audit_trail',
                key: 'reportaudittrail',
                label: t('menu:Audit_Trail'),
                children: [
                    {
                        aclCode: 'seller_audit_trail_master_product',
                        key: 'reportaudittrailmasterproduct',
                        label: t('menu:Master_Product'),
                        url: '/seller/report/audittrail/masterproduct',
                    },
                    {
                        aclCode: 'seller_audit_trail_channel_product',
                        key: 'reportaudittrailchannelproduct',
                        label: t('menu:Channel_Product'),
                        url: '/seller/report/audittrail/channelproduct',
                    },
                ],
            },
        ],
    },
];

export default optionsSeller;
