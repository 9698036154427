/* eslint-disable import/prefer-default-export */
export const optionsDashboard = (t) => [
    { key: 'dashboard', label: t('menu:Dashboard'), url: '/' },
    {
        key: 'manage_page',
        label: t('menu:Manage_Page'),
        url: '/managepage',
        notInAcl: false,
        aclCode: 'acl_manage_page',
    },
];
