/* eslint-disable import/prefer-default-export */
export const optionsAdsCampaign = (t) => [
    {
        key: 'adscampaign',
        label: t('menu:Manage_Ads_Campaign'),
        url: '/adscampaign',
        notInAcl: false,
        aclCode: 'acl_adscampaign_header',
    },
];
