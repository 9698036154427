import React from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import RightToolbar from '@modules/theme/layout/components/rightToolbar';
import { miniDrawerWidthSeller, drawerWidthSeller } from '@modules/theme/layout/helpers';
import SearchHeader from '@modules/theme/layout/components/search/index';
import { BORDER_COLOR, PRIMARY_DARK } from '@theme_color';

const useStyles = makeStyles((theme) => ({
    swiftOmsLogo: {
        padding: '12px 24px 12px 0px',
        borderRight: '1px solid #dde1ec',
        '& img': { height: 36, verticalAlign: 'middle' },
    },
    appBarShiftDesktop: {
        backgroundColor: '#fff',
        position: 'fixed',
        width: '100vw',
        height: 64,
    },
    appBar: {
        backgroundColor: 'white',
        color: PRIMARY_DARK,
        boxShadow: '0px 1px 2px 0px #1118270D',
        marginLeft: miniDrawerWidthSeller,
        width: `calc(100% - ${miniDrawerWidthSeller + 1}px)`,
        zIndex: theme.zIndex.drawer,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            width: '100%',
        },
    },
    appBarShift: {
        marginLeft: drawerWidthSeller,
        width: `calc(100% - ${drawerWidthSeller + 1}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: '2px 0px 20px #4D2F821A',
    },
    togleMenuButton: {
        marginRight: -6,
        width: 24,
        height: 24,
        transform: 'translateX(-24px)',
        [theme.breakpoints.down('md')]: {
            marginRight: 6,
            transform: 'translate(7px, -2px)',
        },
    },
    togleMenuIcon: {
        fontSize: 27,
        color: PRIMARY_DARK,
        borderRadius: '3px',
        background: '#fff',
    },
    iconButton: {
        border: `1px solid ${BORDER_COLOR}`,
        height: 30,
        width: 30,
        borderRadius: 4,
        '& .icon': {
            height: 20,
            width: 20,
            fill: PRIMARY_DARK,
        },
        [theme.breakpoints.down('xs')]: {
            height: 30,
            width: 30,
            '& .icon': {
                height: 16,
                width: 16,
            },
        },
    },
    searchMobile: {
        padding: '0 12px 12px 12px',
        overflow: 'hidden',
        transition: 'max-height 0.3s ease, padding 0.3s ease',
        '&.show': {
            maxHeight: 450,
        },
        '&.hides': {
            maxHeight: 0,
            paddingBottom: 0,
        },
    },
    h4Title: {
        paddingRight: 15,
        marginRight: 5,
        borderRight: `1px solid ${BORDER_COLOR}`,
        marginBottom: 26,
        '&.mobile': {
            padding: '0 0 4px 10px',
            border: 0,
            width: '48vw',
            margin: 0,
        },
    },
}));

const Header = ({
    breadcrumbData,
    open,
    setOpen,
    notificationRes,
    storeLogo,
    setLoadLang,
    backUrl,
    pageConfig,
}) => {
    const classes = useStyles();
    const [openSearch, setOpenSearch] = React.useState(false);
    const isSeller = false;
    const dataAcl = '';

    const HeaderMobile = () => (backUrl ? (
        <AppBar position="fixed" className={clsx(classes.appBar)}>
            <Toolbar>
                <div className={clsx(classes.swiftOmsLogo, open ? 'open' : 'close')}>
                    <img alt="" src={(storeLogo && storeLogo.favicon) || '/assets/img/swiftoms_logo_collapsed.png'} />
                </div>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => setOpen(!open)}
                    className={clsx(classes.togleMenuButton)}
                >
                    <ChevronRightIcon className={classes.togleMenuIcon} />
                </IconButton>
                <RightToolbar notificationRes={notificationRes} setLoadLang={setLoadLang} />
            </Toolbar>
        </AppBar>
    ) : (
        <AppBar position="fixed" className={clsx(classes.appBar)}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => setOpen(!open)}
                    className={clsx(classes.togleMenuButton, 'togle-sidebar')}
                >
                    <MenuIcon className={classes.togleMenuIcon} />
                </IconButton>
                <h4 className={clsx(classes.h4Title, 'mobile')}>{pageConfig?.title}</h4>
                <RightToolbar
                    notificationRes={notificationRes}
                    setLoadLang={setLoadLang}
                    breadcrumbData={breadcrumbData}
                    isSeller={isSeller}
                    dataAcl={dataAcl}
                    openSearch={openSearch}
                    setOpenSearch={setOpenSearch}
                />
            </Toolbar>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
                className={clsx(classes.searchMobile, openSearch ? 'show' : 'hides')}
                onClick={() => setOpenSearch(true)}
            >
                <SearchHeader isSeller={isSeller} dataAcl={dataAcl} />
            </div>
        </AppBar>
    )

    );

    const HeaderDesktop = () => (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, classes.appBarShift)}
        >
            <Toolbar className={clsx(classes.appToolbart)}>
                <Hidden smDown><SearchHeader isSeller={isSeller} dataAcl={dataAcl} /></Hidden>
                <RightToolbar
                    notificationRes={notificationRes}
                    setLoadLang={setLoadLang}
                    breadcrumbData={breadcrumbData}
                    openSearch={openSearch}
                    setOpenSearch={setOpenSearch}
                />
            </Toolbar>
        </AppBar>
    );

    return (
        <>
            <Hidden mdUp implementation="css">
                {HeaderMobile()}
            </Hidden>
            <Hidden smDown implementation="css">
                <div className={classes.appBarShiftDesktop} />
                {HeaderDesktop()}
            </Hidden>
        </>
    );
};

export default Header;
