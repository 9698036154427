/* eslint-disable import/prefer-default-export */
export const optionsAnalytic = (t) => [
    {
        key: 'analytic',
        label: t('menu:analytic'),
        notInAcl: false,
        aclCode: 'acl_analytic_header',
        children: [
            {
                aclCode: 'acl_analytic_rfm',
                key: 'analytic_rfm',
                label: t('menu:analytic_campaign_performance'),
                url: '/analytic/campaign-performance',
                notInAcl: false,
            },
            {
                aclCode: 'acl_analytic_report_rfm',
                key: 'analytic_report_rfm',
                label: t('menu:analytic_report_rfm'),
                url: '/analytic/report-rfm',
                notInAcl: false,
            },
            {
                aclCode: 'acl_analytic_superset_dashboard',
                key: 'superset_dashboard',
                label: t('menu:Superset_Dashboard'),
                url: '/analytic/superset',
                notInAcl: false,
            },
        ],
    },
];
